export const ENVIROMENT_URL = ''
      
//CATCHING ERRORS FETCHING
export const DATA_FETCH_FAILED = 'DATA_FETCH_FAILED'

//USERS
export const REQUEST_USER_LOGIN = 'REQUEST_USER_LOGIN'
export const RESPONSE_USER_LOGIN = 'RESPONSE_USER_LOGIN'

//HOME
export const REQUEST_HOME_MODULE_INFO = 'REQUEST_HOME_MODULE_INFO'
export const RESPONSE_HOME_MODULE_INFO = 'RESPONSE_HOME_MODULE_INFO'

//REDUCER
export const CLEAN_REDUCER = 'CLEAN_REDUCER';